import { QuestionBase } from './question-base';

export class DropdownQuestion extends QuestionBase<string> {
  controlType = 'dropdown';
  options: {key: string, value: string}[] = [];
  actions: any = [];

  constructor(options: {} = {}) {
    super(options);
    this.options = options['options'] || [];
    this.actions = options['actions'];
  }
  actionClick(action: any) {
    if(action.action) action.action();
  }
}