import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

// Amplify
import Amplify from 'aws-amplify';
import amplify from './aws-exports';
const amplifyConfig = Object.assign({}, amplify, {
  "aws_cognito_identity_pool_id": "eu-west-1:ab7cf17c-25d0-4bfc-8b8d-b78673e2fe27",
  "aws_cognito_region": "eu-west-1",
  "aws_user_pools_id": "eu-west-1_A09RdXwq8",
  "aws_user_pools_web_client_id": "6t6mom9prstb339mic3idq61co",
  "aws_user_files_s3_bucket": "paypack.ro-upload-prod",
  "aws_user_files_s3_bucket_region": "eu-west-1"
});
Amplify.configure(amplifyConfig);

//
platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.log(err));
