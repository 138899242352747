import { Component, OnInit, Input } from '@angular/core';
import { AuthService } from '../auth/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-user-header',
  templateUrl: './user-header.component.html',
  styleUrls: ['./user-header.component.css']
})
export class UserHeaderComponent implements OnInit {

  constructor(private authService: AuthService, private router: Router) {};
    private username = '';

    logout() {
      this.authService.logout();
    }
    suppliers() {
      this.router.navigate(['suppliers']);
    }
  
    ngOnInit() {
      setTimeout(() => {
        this.username = (this.authService.getUserProfile()).name;    
      }, 500);
    }  
}
