import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { UserSupplierService } from '../services/user-supplier.service';
import { UserCreateSupplierComponent } from '../user-create-supplier/user-create-supplier.component';

@Component({
  selector: 'app-user-suppliers',
  templateUrl: './user-suppliers.component.html',
  styleUrls: ['./user-suppliers.component.css']
})
export class UserSuppliersComponent implements OnInit {
  suppliers = [];
  constructor(private supplierService: UserSupplierService, private router: Router, private modalService: NgbModal) { }

  getSuppliers() {
    this.supplierService.getSuppliers().subscribe(data => {
      this.suppliers = data.data.suppliers;
    });
  }
  create() {
    //this.router.navigate(['create-supplier']);
    const modalRef = this.modalService.open(UserCreateSupplierComponent, { centered: true });
    modalRef.componentInstance.supplier = {};
    modalRef.result.then((result) => {
      this.getSuppliers();
    })
  }
  ngOnInit() {
    this.getSuppliers();
  }
}
