import * as d3Shape from 'd3-shape';
import { select, Selection } from 'd3-selection';
import 'd3-transition';

export class Serie {
    values: any;
    xAxis: any;
    yAxis: any;
    name: string;
    label: string;
    color: any;
    constructor(xAxis, yAxis, name, label, color?) {
        this.xAxis = xAxis;
        this.yAxis = yAxis;
        this.name = name;
        this.label = label;
        this.color = color;
    };

    draw(parent, values, options?) {}
}
export class LineSerie extends Serie {
    private line: d3Shape.Line<[number, number]>;
    //
    draw(parent, values, options?) {
        this.line = d3Shape.line()
            .x( (d: any) => this.xAxis(d.date) )
            .y( (d: any) => this.yAxis(d.value) );
    
        const dummyData = values.map(function(dd) {
            return {
                date: dd.date,
                value: 0
            } 
        });
        parent.append("g").selectAll(".line")
            .data([{values}])
        .enter().append("path")
            .attr("class", "line")
            .style("fill", "none")
            .style("stroke", d => this.color )
            .style("stroke-width", 2)
            .attr("d", d => this.line(dummyData))
            .attr("transform", "translate(10,0)")
            .transition()
            .attr("d", d => this.line(d.values))
            .duration(1000);
    }
}

export class BarSerie extends Serie {
    //
    draw(parent, values, options) {
        parent.append("g").selectAll(".bar")
            .data(values)
        .enter().append("rect")
            .attr("class", "bar")
            .style("stroke", (d) => this.color )
            .style("fill-opacity", 0.2)
            .style("fill", (d) => this.color )
            .attr("x", (d: any) => this.xAxis(d.date))
            .attr("width", "20px")
            .attr("y", (d: any) => this.yAxis(0))
            .attr("height", 0)
            .transition()
            .attr("y", (d: any) => d.value < 0 ? this.yAxis(0) : this.yAxis(d.value))
            .attr("height", (d: any) => { 
                return Math.abs(this.yAxis(0) - this.yAxis(d.value)); 
            }).duration(1000);
    }
}
