import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { UserSupplierService } from '../services/user-supplier.service';
import { UserCreateSupplierComponent } from '../user-create-supplier/user-create-supplier.component';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { Supplier } from '../model/supplier';
import { MessagesService } from '../services/messages.service'

@Component({
  selector: '[app-user-supplier]',
  templateUrl: './user-supplier.component.html',
  styleUrls: ['./user-supplier.component.css']
})
export class UserSupplierComponent implements OnInit {
  @Input() supplier;
  @Output() supplierChanged = new EventEmitter<Supplier>();
  constructor(private supplierService: UserSupplierService, private modalService: NgbModal, private msg: MessagesService) { }

  ngOnInit() {
  }

  allowDelete() {
    return this.supplier && this.supplier.origin === 'user';
  }
  deleteSupplier() {
    const confirmPrompt = this.modalService.open(ConfirmationDialogComponent, { centered: true });
    confirmPrompt.componentInstance.message = `Esti pe punctul de a suprima furnizorul ${this.supplier.name}. Continuam ?`;
    confirmPrompt.result.then((result) => {
      if (result === 'ok') {
        this.supplierService.deleteSupplier(this.supplier).subscribe(data => {
          if (data.errors) this.msg.addErrors(data.errors);
          else {
            this.msg.addSuccess();
            this.supplierChanged.emit();
          }
        });
      }
    });
  }

  allowEdit() {
    return this.supplier && this.supplier.origin === 'user';
  }
  editSupplier() {
    const modalRef = this.modalService.open(UserCreateSupplierComponent, { centered: true });
    modalRef.componentInstance.supplier = this.supplier;
    modalRef.result.then((result) => {
      this.supplierChanged.emit(this.supplier);
    })
  }
}
