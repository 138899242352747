export class Invoice {
    id: string;
    supplier: string;
    supplierName: string;
    account: string;
    customerCode: string;
    description: string;
    payCode: string;
    invoiceId: string;
    amount: number;
    balance: number;
    selectedAmount: number;
    date: Date;
    dueDate: Date;
    site: string;
    siteDescription?: string;
    status: string;
    statusMessage: string;
    statusDescription: string;
    origin: string;
}

export class SelectedInvoice {
    supplier: string;
    account: string;
    invoiceId: string;
    selectedAmount: number;
}

export class InvoicesResponse {
    data: any;
    errors?: Array<any>;
}