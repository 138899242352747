// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile =  {
    "aws_project_region": "eu-west-1",
    "aws_content_delivery_bucket": "paypack.ro-site-user",
    "aws_content_delivery_bucket_region": "eu-west-1",
    "aws_content_delivery_url": "https://d35x5qz149t0fr.cloudfront.net"
};


export default awsmobile;
