import { Component, OnInit } from '@angular/core';
import { MessagesService } from '../services/messages.service';

@Component({
  selector: 'app-messages',
  templateUrl: './messages.component.html',
  styleUrls: ['./messages.component.css']
})
export class MessagesComponent implements OnInit {
  messages = [];
  constructor(private msgService: MessagesService) { }

  ngOnInit() {
    this.msgService.getMessages().subscribe((msg) => {
      const diag = Object.assign({}, msg);
      switch(msg.severity) {
        case 'error': 
          diag.class = 'alert-danger';
          break;
        case 'warning': 
          diag.class = 'alert-warning';
          break;
        case 'success':
          diag.class = 'alert-success';
          break;
        default:
          diag.class = ''
      }
      this.messages.push(diag);
      if (diag.expire) setTimeout(() => {
        this.removeMessage(msg);
      }, msg.expire * 1000);
    });
  }

  removeMessage(message) {
    this.messages = this.messages.filter((msg) => {
      return msg.id !== message.id;
    });
  }

}
