import { Injectable } from '@angular/core';
import { Router, Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, of, EMPTY }  from 'rxjs';
import { mergeMap, take } from 'rxjs/operators';

import { UserPaymentService } from './user-payment.service';
import { Basket } from '../model/basket';

@Injectable({
  providedIn: 'root'
})
export class UserPaymentResolverService implements Resolve<Basket> {

  constructor(private router: Router, private userPaymentService: UserPaymentService) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Basket> | Observable<never> {
    let id = route.paramMap.get('id');

    return this.userPaymentService.getBasket(id).pipe(
      take(1),
      mergeMap(resp => {
        if (resp.data.basket) {
          return of(resp.data.basket);
        } else { // id not found
          this.router.navigate(['/payments']);
          return EMPTY;
        }
      })
    );
  }
}
