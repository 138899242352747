import { Injectable, EventEmitter } from '@angular/core';
//import { UserManager, UserManagerSettings, User } from 'oidc-client';
import { Observable } from 'rxjs';
import { fromPromise } from 'rxjs/observable/fromPromise';
import 'rxjs/add/operator/map';
import { CognitoAuth, CognitoIdToken } from 'amazon-cognito-auth-js';
import { Router } from '@angular/router';

const authParams = {
  ClientId : '6t6mom9prstb339mic3idq61co', // Your client id here
  AppWebDomain : 'paypack.auth.eu-west-1.amazoncognito.com',
  TokenScopesArray : ['profile', 'openid'], // e.g.['phone', 'email', 'profile','openid', 'aws.cognito.signin.user.admin'],
  RedirectUriSignIn : `${window.location.origin}/auth-callback/`,
  RedirectUriSignOut : `${window.location.origin}/auth-signout/`,
  UserPoolId : 'eu-west-1_A09RdXwq8', // Your user pool id here
//  Storage: '<TODO the storage object>' // OPTIONAL e.g. new CookieStorage(), to use the specified storage provided
};

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  //
  loggedIn: boolean = false;
  auth: CognitoAuth;
  authData: any;
  userProfile: any;
  constructor(private router: Router) {
    const self = this;
    this.auth = new CognitoAuth(authParams);
    this.auth.userhandler = {
      onSuccess: (result) => {
        self.router.navigate(['dashboard']);
      },
      onFailure: (err) => {
        self.router.navigate(['unauthorized']);
      }
    };   
  }
  //
  login() {
    this.auth.getSession();
  }
  logout() {
    this.auth.signOut();
  }
  onCallback() {
    this.auth.parseCognitoWebResponse(window.location.href);
  }
  getToken() {
    return this.auth.signInUserSession && this.auth.signInUserSession.idToken && this.auth.signInUserSession.idToken.jwtToken;
  }
  getUserProfile() {
    const jwt = this.getToken();
    return (jwt && JSON.parse(atob(jwt.split('.')[1]))) || {};
  }
  isLoggedIn() {
    const jwt = this.getToken();
    if (!jwt) return false;
    //
    const exp = JSON.parse(atob(jwt.split('.')[1])).exp;
    return exp > Date.now()/1000;
  }
  unauthorized() {
    this.router.navigate(['unauthorized']);
  }
}
