import { Injectable }   from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

import { QuestionBase } from '../model/question-base';

@Injectable()
export class QuestionControlService {
  constructor() { }

  toFormGroup(questions: QuestionBase<any>[] ) {
    let group: any = {};

    questions.forEach(question => {
        const value = question.value != null ? question.value : '';
        const validators = question.validators || [];
        if (question.required) validators.push(Validators.required);
//        if (question['type'] === 'number') validators.push(Validators.pattern(''))
        group[question.key] = new FormControl(value, {
          validators,
          updateOn: 'blur'
        });
    });
    return new FormGroup(group);
  }
}