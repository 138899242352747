import { Component, OnInit } from '@angular/core';
import * as d3 from 'd3-selection';
import * as d3Scale from 'd3-scale';
import * as d3Shape from 'd3-shape';
import * as d3Array from 'd3-array';
import * as d3Axis from 'd3-axis';
import * as d3ScaleChrome from 'd3-scale-chromatic';
import * as d3Format from 'd3-format';

import { Serie, LineSerie, BarSerie } from './d3graphs';

import { InvoicesService } from '../services/invoices.service';

@Component({
  selector: 'app-user-invoices-graph',
  templateUrl: './user-invoices-graph.component.html',
  styleUrls: ['./user-invoices-graph.component.css']
})

export class UserInvoicesGraphComponent implements OnInit {
  private margin = {top: 20, right: 20, bottom: 30, left: 50};
  private width: number;
  private height: number;
  private x: any;
  private y: any = [];
  private svg: any;
  private legend: any;
  private colors;
  //
  canDisplay = true;
  //
  options = {
    id: 'invoices-graph-container',
    title: 'Facturi de plata',
    series: [{
      measure: 'balance',
      label: 'Facturi',
      type: 'bar'
    }, {
      measure: 'totalBalance',
      label: 'Total cumulat',
      type: 'line',
      strokeWidth: 3
    }],
    xAxis: 'date',
    yAxis: [['auto', 'auto']],
    width: 800,
    height: 250
  }
  //
  legendH = 50; 
  legendTW = 100;
  //
  constructor(private invoicesService: InvoicesService) {
    const self = this;
  }
  //
  _addLegend(serie_name, label, index) {
    this.legend.append("text")
        .attr("transform", "translate(" + index * this.legendTW + ", 0)")
        .attr("dy", ".71em")
        .style("text-anchor", "middle")
        .style("stroke", function(d) { return this.color(serie_name); })
        .text(label);
  }

  render(data) {
    this.initSvg();
    this.initAxis(data);
    this.drawAxis();
    this.drawGraph(data);
  }
  //
  private initSvg() {
    const container = document.getElementById(this.options.id);
    this.width = container.clientWidth - this.margin.left - this.margin.right - this.legendTW;
    this.height = container.clientHeight - this.margin.top - this.margin.bottom - this.legendH;
    d3Format.formatDefaultLocale({
        "decimal": ",",
        "thousands": " ",
        "grouping": [3],
        "currency": ["", "lei"]
    })
    const options = this.options;
    d3.selectAll("#" + options.id + " > *").remove();
    var parent = d3.select("#" + options.id);
    if (options.title)  {
        parent.append("div")
            .text(options.title)
            .attr("class", "invoice-chart-title")
            .attr("style", "font-size:36px");
    }
    var drawArea = parent.append("svg")
        .attr("width", options.width)
        .attr("height", options.height);
    this.svg = drawArea.append("g")
        .attr("transform", "translate(" + this.margin.left + "," + (this.margin.top) + ")");
    //
    var lgCount = options.series.length > 1 ? options.series.length : (Array.isArray(options.series[0].label) ? options.series[0].label.length : 1);
    this.legend = drawArea.append("g")
        .attr("transform", "translate(" + (options.width - lgCount * this.legendTW - this.margin.right) + ",10)");
  };
  private initAxis(data) {
    this.colors = d3Scale.scaleOrdinal(d3ScaleChrome.schemeCategory10);
    this.x = d3Scale.scaleTime().range([0, this.width]);
    this.y[0] = d3Scale.scaleLinear().range([this.height, 0]);
    this.y[0].ticks(5).map(this.y[0].tickFormat(5, ));
    this.y[1] = d3Scale.scaleLinear().range([this.height, 0]);
    this.x.domain(d3Array.extent(data[0].values, (d: any) => d.date ));
    let extent: any = d3Array.extent(data[0].values, (d: any) => d.value );
    if (extent[0] > 0) extent[0] = 0;
    this.y[0].domain(extent);
    extent = d3Array.extent(data[1].values, (d: any) => d.value );
    if (extent[0] > 0) extent[0] = 0;
    this.y[1].domain(extent);
  }
  private drawAxis() {    
    this.svg.append('g')
        .attr('class', 'axis axis--x')
        .attr('transform', 'translate(10,' + this.y[0](0) + ')')
        .call(d3Axis.axisBottom(this.x));

    this.svg.append('g')
        .attr('class', 'axis axis--y')
        .call(d3Axis.axisLeft(this.y[0]))
        .style("stroke", d => this.colors(0))
        .append('text')
        .attr('class', 'axis-title')
        .attr('transform', 'rotate(-90)')
        .attr('y', -50)
        .attr('dy', '.71em')
        .style('text-anchor', 'end')
        .style("stroke", d => this.colors(0))
        .text('Facturi (lei)');
    this.svg.append('g')
        .attr('class', 'axis axis--y')
        .attr('transform', `translate(${this.width + 20},0)`)
        .call(d3Axis.axisRight(this.y[1]))
        .style("stroke", d => this.colors(1))
        .append('text')
        .attr('class', 'axis-title')
        .attr('transform', 'rotate(-90)')
        .attr('y', 50)
        .attr('dy', '.71em')
        .style('text-anchor', 'end')
        .style("stroke", d => this.colors(1))
        .text('Total cumulat (lei)');
  }
    private drawGraph(data) {
        const options = this.options;
        const serie0 = new BarSerie(this.x, this.y[0], data[0].name, data[0].label, this.colors(0));
        serie0.draw(this.svg, data[0].values, {
            height: this.height
        })
        const serie1 = new LineSerie(this.x, this.y[1], data[1].name, data[1].label, this.colors(1));
        serie1.draw(this.svg, data[1].values);
    }
  //
  ngOnInit() {  }

  ngAfterContentInit() {
    const minDate = (new Date().getTime()) - 60 * 24 * 3600 * 1000;
    this.invoicesService.getInvoicesSubject(true).subscribe(data => {
        this.canDisplay = true;
        if (!data.data.invoices.length) {
            this.canDisplay = false;
            return; 
        }
        const renderMap = data.data.invoices.reduce((prev, crt, index) => {
            const date = new Date(crt.dueDate);
            if (date.getTime() < minDate) return prev;
            if (!prev[crt.dueDate]) prev[crt.dueDate] = {
            date,
            value: 0
            }
            prev[crt.dueDate].value += crt.balance;
            //
            return prev;
        }, {});
        const series = [{
            num: 0,
            name: 'invoices',
            label: 'Facturi',
            values: Object.keys(renderMap).map(key => renderMap[key]).sort((a, b) => { return a.date.getTime() - b.date.getTime(); })
        }];
        const s0vals = series[0].values;
        const s1vals = [{
            date: s0vals[0].date,
            value: s0vals[0].value
        }];
        let prev = s1vals[0];
        for (let idx = 1; idx < s0vals.length; idx++) {
            const crt = s0vals[idx];
            // insert low step
            s1vals.push({
                date: crt.date,
                value: prev.value
            });
            s1vals.push(prev = {
                date: crt.date,
                value: prev.value + crt.value
            })
        }
        let total = 0;
        series[1] = {
            num: 1,
            name: 'total',
            label: 'Total',
            values: s1vals
        }
        //
        this.render(series);
    })
  }

}
