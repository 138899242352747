import { Component, OnInit } from '@angular/core';

import { Basket } from '../../model/basket';

import { UserPaymentService } from '../../services/user-payment.service';
import { MessagesService } from '../../../services/messages.service';

@Component({
  selector: 'app-user-payments',
  templateUrl: './user-payments.component.html',
  styleUrls: ['./user-payments.component.css']
})
export class UserPaymentsComponent implements OnInit {
  payments = [];
  constructor(private paymentService: UserPaymentService) { }

  ngOnInit() {
    this.paymentService.getBaskets().subscribe(resp => {
      this.payments = (resp.data.baskets || []).sort((a: Basket, b: Basket) => {
        return new Date(a.date).getTime() - new Date(b.date).getTime();
      });
    })
  }
}
