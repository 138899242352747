import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
//
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
//
import { Invoice, SelectedInvoice, InvoicesResponse } from '../model/invoice';
import { InvoicesService } from '../services/invoices.service';
import { UserSupplierService } from '../services/user-supplier.service';
import { MessagesService } from '../services/messages.service';
//
import { UserEditSiteComponent } from '../user-edit-site/user-edit-site.component';

@Component({
  selector: 'app-user-invoices',
  templateUrl: './user-invoices.component.html',
  styleUrls: ['./user-invoices.component.css']
})
export class UserInvoicesComponent implements OnInit {
  //
  invoices: Invoice[];
  selectedInvoicesMap: any = {};
  suppliersMap: any = {};
  sitesMap: any = {};
  //
  constructor(private invoicesService: InvoicesService, private supplierService: UserSupplierService, private router: Router, 
      private msg: MessagesService, private modalService: NgbModal) { }
    //
    getCompositeId(invoice: Invoice) {
      return `${invoice.supplier}.${invoice.invoiceId}`;
  }
  mergeInformation() {
    if (this.invoices)
      for (let invoice of this.invoices) {
        invoice.supplierName = (this.suppliersMap[invoice.supplier] && this.suppliersMap[invoice.supplier].name) || invoice.supplier;
        invoice.siteDescription = invoice.site && ((this.sitesMap[`${invoice.supplier}.${invoice.site}`] && this.sitesMap[`${invoice.supplier}.${invoice.site}`].description) || invoice.site);
      }
  }
  //
  getSuppliers() {
    this.supplierService.getSuppliers().subscribe(data => {
      this.suppliersMap = data.data.suppliers.reduce((prev, supplier) => {
        prev[supplier.id] = supplier;
        if (supplier.cui) prev[supplier.cui] = supplier;
        return prev;
      }, {});
      // apply to invoices
      this.mergeInformation();
    });
  }
  getSites() {
    this.supplierService.getSites().subscribe(data => {
      this.sitesMap = data.data.sites.reduce((prev, site) => {
        prev[`${site.supplier}.${site.id}`] = site;
        return prev;
      }, {});
      // apply to invoices
      this.mergeInformation();
    });
  }
  getInvoices() {
    this.invoicesService.getInvoices(false).subscribe(data => {
      this.invoices = data.data.invoices.sort((a, b) => {
        return new Date(a.dueDate).getTime() - new Date(b.dueDate).getTime();
      });
      // apply supplier
      this.mergeInformation();
    });
  }
  create() {
    this.router.navigate(['create-invoice']);
  }
    
  editSite(invoice) {
    const modalRef = this.modalService.open(UserEditSiteComponent, { centered: true });
    modalRef.componentInstance.site = {
      supplier: invoice.supplier,
      id: invoice.site,
      description: invoice.siteDescription
    };
    modalRef.result.then((result) => {
      this.getSites();
    })
  }

  ngOnInit() {
    //
    this.getSuppliers();
    this.getSites();
    this.getInvoices();
  }

}
