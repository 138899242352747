import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
//
import { DropdownQuestion } from '../model/question-dropdown';
import { TextboxQuestion } from '../model/question-textbox';
import { QuestionBase } from 'src/app/model/question-base';
import { DynamicFormComponent } from '../dynamic-form/dynamic-form.component';
import { QuestionControlService }    from '../services/question-control.service';
//
import { Invoice, InvoicesResponse } from '../model/invoice';
import { InvoicesService } from '../services/invoices.service';
import { UserSupplierService } from '../services/user-supplier.service';
import { MessagesService } from '../services/messages.service'
import { UserPdfService } from '../services/user-pdf.service';

@Component({
  selector: 'app-user-create-invoice',
  templateUrl: './user-create-invoice.component.html',
  styleUrls: ['./user-create-invoice.component.css']
})
export class UserCreateInvoiceComponent implements OnInit {
  @ViewChild('file') file;
  invoice: any = null;
  suppliers = [];
  //
  editMode = false;
  phase = '';
  phaseMessage1 = '';
  phaseMessage2 = '';
  //
  questions: QuestionBase<any>[] = [new DropdownQuestion({
        key: 'supplier',
        label: 'Furnizor',
        options: this.suppliers,
        required: true,
  /*      actions: [{
          label: 'Adauga un furnizor',
          action: this.getRouteAction('create-supplier')
        }],*/
        order: 1
      }),
      new TextboxQuestion({
        key: 'description',
        label: 'Descriere',
        required: true,
        order: 2
      }),
      new TextboxQuestion({
        key: 'invoiceId',
        label: 'Numar',
        required: false,
        order: 3
      }),
      new TextboxQuestion({
        key: 'date',
        label: 'Data',
        type: 'date',
        required: true,
        order: 4
      }),
      new TextboxQuestion({
        key: 'amount',
        label: 'Total factura',
        type: 'number',
        required: true,
        value: 0,
        order: 5
      }),
      new TextboxQuestion({
        key: 'dueDate',
        label: 'Scadenta',
        type: 'date',
        required: true,
        order: 6
      }),
      new TextboxQuestion({
        key: 'tempDocument',
        label: 'Temp Document',
        required: false,
        order: 99,
        hidden: true
      }),
      new TextboxQuestion({
        key: 'customerCode',
        label: 'Customer code',
        required: false,
        order: 99,
        hidden: true
      }),
      new TextboxQuestion({
        key: 'payCode',
        label: 'Paycode',
        required: false,
        order: 99,
        hidden: true
      }),
      new TextboxQuestion({
        key: 'balance',
        label: 'Balance',
        required: false,
        order: 99,
        hidden: true
      }),
      new TextboxQuestion({
        key: 'site',
        label: 'Site',
        required: false,
        order: 99,
        hidden: true
      })
    ].sort((a, b) => a.order - b.order);

  constructor(private invoicesService: InvoicesService, private supplierService: UserSupplierService, private router: Router, 
      protected qcs: QuestionControlService, private msgService: MessagesService, private userPdfService: UserPdfService) { 
  }
  //
  async uploadPdf() {
    try {
      this.phase = 'upload';
      const ff = this.file.nativeElement.files[0];
      const tempDocument = await this.userPdfService.uploadPdf(ff, (progress) => {
        this.phaseMessage1 = `Incarcare in curs ${Math.round(progress.loaded*100/progress.total)}%`;
      });
      this.phaseMessage2 = 'Tratament in curs';
      // execute decode
      this.userPdfService.convertPdf(tempDocument).subscribe(resp => {
        // load form
        if (!resp || !resp.supplier) {
          this.msgService.addMessage('Furnizorul nu a putut fi identificat sau format de fichier necunoscut.', 'warning');
          //
          this.invoice = {
            tempDocument
          }
          //
          this.showInvoice();
          //
          return;
        }
        resp.dueDate = resp.dueDate || resp.date;
        const { supplier, description, invoiceId, date, amount, dueDate, customerCode, site, balance, payCode } = resp;
        this.invoice = { supplier, description, invoiceId, date, amount, dueDate, tempDocument, customerCode, site, balance, payCode };
        //
        this.phase = 'input';
        //
        this.showInvoice();
      });
    } catch(e) {
      this.msgService.addErrors([
        'Eroare de incarcare fisier',
        e
      ]);
    }
  }
  // UI Management stuff
  showInvoice() {
    this.editMode = true;
  }
  getInitialSelectDisplayMode() {
    return this.editMode ? 'none' : 'block';
  }
  getManualButtonDisplayMode() {
    return this.editMode || this.phase ? 'none' : 'block';
  }
  getMessagesDisplayMode() {
    return this.editMode || this.phase ? 'block' : 'none';
  }
  //
  getRouteAction(route) {
    const self = this;
    return () => {
      self.router.navigate([route]);
    }
  }
  ngOnInit() {
    this.supplierService.getSuppliers().subscribe(data => {
      this.suppliers = data.data.suppliers.map(supplier => {
        return {
          key: supplier.cui || supplier.id,
          value: supplier.name
        }
      })
      this.questions[0]['options'] = this.suppliers;
//      this.questions[1].value = 'AAA';
    });
  }

  getSubmitHandler() {
    return this.invoicesService.createInvoice.bind(this.invoicesService);
  }

}
