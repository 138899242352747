import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MessagesService {
  private observable;
  private observer;
  private msgId = 0;
  constructor() { 
    this.observable = Observable.create(observer => {
      this.observer = observer;
    });
  }

  addMessage(message: string, severity: string, expire?: number) {
    this.observer.next({
      id: this.msgId++,
      message,
      severity,
      expire
    });
  }
  addErrors(errors) {
    for (let err of errors) this.addMessage(err.message, 'error');
  }
  addSuccess() {
    this.addMessage('Succes', 'success', 3);
  }
  getMessages(): Observable<any> {
    return this.observable;
  }
}
