import { Injectable } from '@angular/core';
import {
    HttpRequest,
    HttpResponse,
    HttpErrorResponse,
    HttpHandler,
    HttpEvent,
    HttpInterceptor
} from '@angular/common/http';
import { AuthService } from './auth.service';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/do';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {

  constructor(public auth: AuthService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    document.getElementById('body').style.cursor = 'wait';
    const start = Date.now();
    return next.handle(request).do((event: HttpEvent<any>) => {
      if (event instanceof HttpResponse) {
        document.getElementById('body').style.cursor = 'default';
      }
    }, (err: any) => {
      if (err instanceof HttpErrorResponse) {
        if (err.status === 0 || err.status === 401) {
          this.auth.unauthorized();
        }
      }
    });
  }
}