import { Component, OnInit } from '@angular/core';
import { InvoicesService } from '../services/invoices.service';

@Component({
  selector: 'app-user-dashboard',
  templateUrl: './user-dashboard.component.html',
  styleUrls: ['./user-dashboard.component.css']
})
export class UserDashboardComponent implements OnInit {
  vestedAmount = 0;
  totalAmount = 0;
  canDisplay = true;
  constructor(private invoicesService: InvoicesService) { }

  ngOnInit() {
    this.invoicesService.getInvoicesSubject(true).subscribe(data => {
      const now = new Date().getTime();
      this.totalAmount = 0;
      this.vestedAmount = 0;
      for (let invoice of data.data.invoices) {
        this.totalAmount += invoice.amount;
        if (new Date(invoice.dueDate || invoice.date).getTime() < now) this.vestedAmount += invoice.amount;
      }
      //
      this.canDisplay = (!!data.data.invoices.length);        
    });
  }

}
