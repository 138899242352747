import { NgModule }             from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CommonModule } from '@angular/common';
//
import { UserPaymentDetailsComponent } from './components/user-payment-details/user-payment-details.component';
import { UserPaymentsComponent } from './components/user-payments/user-payments.component';
//
import { UserPaymentResolverService } from './services/user-payment-resolver.service';
import { AuthGuardService } from '../auth/auth-guard.service';

const routes: Routes = [{
  path: 'payment', component: UserPaymentDetailsComponent, canActivate: [AuthGuardService]
}, {
  path: 'payment/:id', component: UserPaymentDetailsComponent, canActivate: [AuthGuardService], resolve: { basket: UserPaymentResolverService }
}, {
  path: 'payments', component: UserPaymentsComponent, canActivate: [AuthGuardService]
}];

@NgModule({
  imports: [ RouterModule.forChild(routes) ],
  exports: [ RouterModule ]
})

export class UserPaymentsRoutingModule { }
