import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  constructor() { }

  get endpoints() {
    return {
      basket: 'https://b2altk0a1e.execute-api.eu-west-1.amazonaws.com/prod/basket',
      mobilpay_confirm: 'https://b2altk0a1e.execute-api.eu-west-1.amazonaws.com/prod/mobilpay_confirm'
    };
  }
  get mobilpay() {
    return {
      redirect: 'https://sandboxsecure.mobilpay.ro'
    };
  }
}
