import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { Basket, PaymentHistory } from '../../model/basket';

import { UserPaymentService } from '../../services/user-payment.service';

import { UserSupplierService } from '../../../services/user-supplier.service';
import { MessagesService } from '../../../services/messages.service';

@Component({
  selector: 'app-user-payment-details',
  templateUrl: './user-payment-details.component.html',
  styleUrls: ['./user-payment-details.component.css']
})
export class UserPaymentDetailsComponent implements OnInit {
  @Input() basket: any = {};
  suppliersMap = {};
  
  constructor(private paymentService: UserPaymentService, private supplierService: UserSupplierService, private route: ActivatedRoute, private msg: MessagesService) { }
  
  mergeInformation() {
    if (this.basket.invoices)
      for (let invoice of this.basket.invoices) {
        invoice.supplierName = (this.suppliersMap[invoice.supplier] && this.suppliersMap[invoice.supplier].name) || invoice.supplier;
      }
    this.basket.history = (this.basket.history || []).sort((a: PaymentHistory, b: PaymentHistory) => {
      return new Date(a.date).getTime() - new Date(b.date).getTime();
    });
}
  //
  getSuppliers() {
    this.supplierService.getSuppliers().subscribe(data => {
      this.suppliersMap = data.data.suppliers.reduce((prev, supplier) => {
        prev[supplier.id] = supplier;
        if (supplier.cui) prev[supplier.cui] = supplier;
        return prev;
      }, {});
      // apply to invoices
      this.mergeInformation();
    });
  }

  ngOnInit() {
    this.getSuppliers();
    //
    this.route.queryParamMap.subscribe(query => {
      const params = query['params'];
      if (params['orderId']) {
        this.paymentService.getBasket(params['orderId']).subscribe(resp => {
          if (resp.errors) return this.msg.addErrors(resp.errors);
          this.basket = resp.data.basket;
          //
          this.mergeInformation();
        })
      }
    });
    this.route.data.subscribe((data: { basket: Basket }) => {
      this.basket = data.basket;
      //
      this.mergeInformation();
    })
  }
}