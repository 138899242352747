import { Component, OnInit, Input } from '@angular/core';

import { TextboxQuestion } from '../model/question-textbox';
import { QuestionBase } from 'src/app/model/question-base';
import { DynamicFormComponent } from '../dynamic-form/dynamic-form.component';
import { QuestionControlService }    from '../services/question-control.service';

import { UserSupplierService } from '../services/user-supplier.service';

import { Site } from '../model/supplier';

@Component({
  selector: 'app-user-edit-site',
  templateUrl: './user-edit-site.component.html',
  styleUrls: ['./user-edit-site.component.css']
})
export class UserEditSiteComponent implements OnInit {
  @Input() site;

  questions: QuestionBase<any>[];
  //
  constructor(private supplierService: UserSupplierService) { }

  ngOnInit() {
    this.questions = [
      new TextboxQuestion({
        key: 'description',
        label: 'Denumire',
        required: false,
        value: this.site.description,
        order: 1
      })]
    }

  getSubmitHandler() {
    return ((site) => {
      const newSite = Object.assign({}, this.site, site);
      return this.supplierService.upsertSite(newSite);
    }).bind(this);
  }
}
