import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { Invoice, InvoicesResponse } from '../model/invoice';
import { InvoicesService } from '../services/invoices.service';
import { MessagesService } from '../services/messages.service'

import { UserEditSiteComponent } from '../user-edit-site/user-edit-site.component';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';

@Component({
  selector: 'app-user-invoice',
  templateUrl: './user-invoice.component.html',
  styleUrls: ['./user-invoice.component.css']
})
export class UserInvoiceComponent implements OnInit {
  @Input() invoice: Invoice;
  @Output() selectedToggle = new EventEmitter<Invoice>();
  @Output() invoiceDeleted = new EventEmitter<void>();
  @Output() siteChanged = new EventEmitter<Invoice>();

  constructor(private invoicesService: InvoicesService, private router: Router, private msg: MessagesService, private modalService: NgbModal) { }
  // UI HELPERS
  getSelectedColor() {
    return this.invoice.selectedAmount > 0 ? 'green' : 'dimgrey';
  }
  getSelectedAmountColor(amount) {
    return this.invoice.selectedAmount === amount ? 'green' : 'dimgrey';
  }
  allowDelete() {
    return this.invoice.origin === 'user';
  }
  allowArchive() {
    return this.invoice.origin !== 'user';
  }
  getDueDateDays() {
    const diff = Math.round((new Date(this.invoice.dueDate || this.invoice.date).getTime() - new Date().getTime()) / (1000 * 24 * 3600));
    if (diff < 0) return '+' + (-1) * diff;
    else return '-' + diff;
  }
  isExpired() {
    return new Date(this.invoice.dueDate || this.invoice.date).getTime() < new Date().getTime();
  }
  isMultiAmountSelect() {
    const invoice = this.invoice;
    //
    if (invoice.selectedAmount) return false;
    //
    return invoice.balance > 0 && invoice.amount > 0 && invoice.amount !== invoice.balance;
  }
  isSingleAmountSelect() {
    const invoice = this.invoice;
    //
    if (invoice.selectedAmount) return true;
    //
    return invoice.balance > 0 && invoice.amount > 0 && invoice.amount === invoice.balance;
  }
  // ACTIONS
  selectInvoice(amount) {
    this.invoice.selectedAmount = this.invoice.selectedAmount ? 0 : amount;
    this.selectedToggle.emit(this.invoice);
  }
  deleteInvoice() {
    const confirmPrompt = this.modalService.open(ConfirmationDialogComponent, { centered: true });
    confirmPrompt.componentInstance.message = `Esti pe punctul de a suprima factura ${this.invoice.invoiceId}. Continuam ?`;
    confirmPrompt.result.then((result) => {
      if (result === 'ok') {
        this.invoicesService.deleteInvoice(this.invoice).subscribe(data => {
          if (data.errors) this.msg.addErrors(data.errors);
          else {
            this.msg.addSuccess();
            this.invoiceDeleted.emit();
          }
        });
      }
    });
  }
  archiveInvoice() {
    const confirmPrompt = this.modalService.open(ConfirmationDialogComponent, { centered: true });
    confirmPrompt.componentInstance.message = `Esti pe punctul de a arhiva factura ${this.invoice.invoiceId}. Continuam ?`;
    confirmPrompt.result.then((result) => {
      if (result === 'ok') {
        this.invoicesService.archiveInvoice(this.invoice).subscribe(data => {
          if (data.errors) this.msg.addErrors(data.errors);
          else {
            this.msg.addSuccess();
            this.invoiceDeleted.emit();
          }
        });
      }
    });
  }
  donwloadInvoice() {
    this.invoicesService.getDownloadUrl(this.invoice).subscribe(data => {
      const a = document.getElementById('document_a') as HTMLAnchorElement;
      a.href = data.data.document_url;
      a.setAttribute('download', `${this.invoice.supplier}-${this.invoice.invoiceId}-${new Date(this.invoice.date).toISOString().split('T')[0]}.pdf`);
      a.click();
    });
  }
  editSite() {
    const modalRef = this.modalService.open(UserEditSiteComponent, { centered: true });
    modalRef.componentInstance.site = {
      supplier: this.invoice.supplier,
      id: this.invoice.site,
      description: this.invoice.siteDescription
    };
    modalRef.result.then((result) => {
      this.siteChanged.emit(this.invoice);
    })
  }
  //
  ngOnInit() {
  }

}
