import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Supplier, SupplierResponse, Site, SiteResponse } from '../model/supplier';

@Injectable({
  providedIn: 'root'
})
export class UserSupplierService {
  suppliersUrl: string = 'https://lns60qr7ac.execute-api.eu-west-1.amazonaws.com/prod/suppliers';
  
  constructor(private http: HttpClient) { }
  
  getSuppliers(): Observable<SupplierResponse> {
    const fields = ['id', 'account', 'name', 'description', 'cui', 'iban', 'origin'].join(',');
    const select = `{suppliers{${fields}}}`;
    return this.http.get<SupplierResponse>(`${this.suppliersUrl}?query=${encodeURIComponent(select)}`);
  }
  createSupplier(supplier): Observable<SupplierResponse> {
    supplier.iban = (supplier.iban || '').replace(/\s/g, '');
    supplier.cui = supplier.cui || '';
    const fields = ['id', 'account', 'name', 'description', 'cui', 'iban'];
    const select = `mutation Supplier_mutation{
        createSupplier(supplier:{
        account:"account",
        name:"${supplier.name}",
        description:"${supplier.description}",
        cui:"${supplier.cui}",
        iban:"${supplier.iban}",
        origin: "user"
      }) {${fields.join(',')}}
    }`;
    return this.http.get<SupplierResponse>(`${this.suppliersUrl}?query=${encodeURIComponent(select)}`);
  }
  deleteSupplier(supplier): Observable<SupplierResponse> {
    const select = `mutation Supplier_mutation{
        deleteSupplier(id:"${supplier.id}") {id}
    }`;
    return this.http.get<SupplierResponse>(`${this.suppliersUrl}?query=${encodeURIComponent(select)}`);
  }
  modifySupplier(supplier): Observable<SupplierResponse> {
    supplier.iban = (supplier.iban || '').replace(/\s/g, '');
    supplier.cui = supplier.cui || '';
    const fields = ['id', 'account', 'name', 'description', 'cui', 'iban'];
    const select = `mutation Supplier_mutation{
        modifySupplier(supplier:{
        account:"account",
        id:"${supplier.id}",
        name:"${supplier.name}",
        description:"${supplier.description}",
        cui:"${supplier.cui}",
        iban:"${supplier.iban}",
        origin: "user"
      }) {${fields.join(',')}}
    }`;
    return this.http.get<SupplierResponse>(`${this.suppliersUrl}?query=${encodeURIComponent(select)}`);
  }
  // Sites
  getSites(): Observable<SiteResponse> {
    const fields = ['id', 'account', 'supplier', 'description'].join(',');
    const select = `{sites{${fields}}}`;
    return this.http.get<SiteResponse>(`${this.suppliersUrl}?query=${encodeURIComponent(select)}`);
  }
  upsertSite(site): Observable<SiteResponse> {
    const select = `mutation Supplier_mutation{
        upsertSite(site:{
        account:"account",
        id:"${site.id}",
        supplier:"${site.supplier}",
        description:"${site.description}"
      }) {id}
    }`;
    return this.http.get<SupplierResponse>(`${this.suppliersUrl}?query=${encodeURIComponent(select)}`);
  }
}
