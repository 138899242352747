import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http'
import { LOCALE_ID } from '@angular/core';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { TokenInterceptor } from './auth/token.interceptor';
import { JwtInterceptor } from './auth/jwt.interceptor';

import { AppComponent } from './app.component';

import { UserPaymentsModule } from './user-payments/payments.module';
import { UserPaymentsRoutingModule } from './user-payments/user-payments-routing.module';

import { UserInvoicesComponent } from './user-invoices/user-invoices.component';
import { AppRoutingModule } from './/app-routing.module';
import { AuthGuardService } from './auth/auth-guard.service';
import { AuthService } from './auth/auth.service';
import { AuthCallbackComponent } from './auth-callback/auth-callback.component';
import { UnauthorizedComponent } from './unauthorized/unauthorized.component';
import { AuthSignoutComponent } from './auth-signout/auth-signout.component';
import { UserCreateInvoiceComponent } from './user-create-invoice/user-create-invoice.component';
import { DynamicFormComponent } from './dynamic-form/dynamic-form.component';
import { DynamicFormQuestionComponent } from './dynamic-form-question/dynamic-form-question.component';
import { QuestionControlService } from './services/question-control.service';
import { MessagesService } from './services/messages.service';
import { UserPdfService } from './services/user-pdf.service';

import { registerLocaleData } from '@angular/common';
import { MessagesComponent } from './messages/messages.component';
import { UserDashboardComponent } from './user-dashboard/user-dashboard.component';
import { UserInvoiceComponent } from './user-invoice/user-invoice.component';

import { IBANPipe } from './pipes/iban';

import localeFr from '@angular/common/locales/fr';
import localeFrExtra from '@angular/common/locales/extra/fr';
import localeRo from '@angular/common/locales/ro';
import { UserHeaderComponent } from './user-header/user-header.component';
import { UserActivityComponent } from './user-activity/user-activity.component';
import { UserEditSiteComponent } from './user-edit-site/user-edit-site.component';
import { UserSuppliersComponent } from './user-suppliers/user-suppliers.component';
import { UserCreateSupplierComponent } from './user-create-supplier/user-create-supplier.component';
import { UserSupplierComponent } from './user-supplier/user-supplier.component';
import { UserInvoicesGraphComponent } from './user-invoices-graph/user-invoices-graph.component';
import { DynamicFormModalComponent } from './dynamic-form-modal/dynamic-form-modal.component';

import { AmplifyAngularModule, AmplifyService } from 'aws-amplify-angular';
import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component';
import { UserPendingInvoicesComponent } from './user-pending-invoices/user-pending-invoices.component';

registerLocaleData(localeFr, 'fr-FR', localeFrExtra);
// hack thousend separator
localeRo[13][0] = '.';
localeRo[13][1] = ' ';
registerLocaleData(localeRo, 'ro');

@NgModule({
  declarations: [
    AppComponent,
    UserInvoicesComponent,
    AuthCallbackComponent,
    UnauthorizedComponent,
    AuthSignoutComponent,
    UserCreateInvoiceComponent,
    DynamicFormComponent,
    DynamicFormQuestionComponent,
    MessagesComponent,
    UserDashboardComponent,
    UserInvoiceComponent,
    UserHeaderComponent,
    UserActivityComponent,
    UserEditSiteComponent,
    UserSuppliersComponent,
    UserCreateSupplierComponent,
    UserSupplierComponent,
    IBANPipe,
    UserInvoicesGraphComponent,
    DynamicFormModalComponent,
    ConfirmationDialogComponent,
    UserPendingInvoicesComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    UserPaymentsRoutingModule,
    ReactiveFormsModule,
    AmplifyAngularModule,
    UserPaymentsModule,
    NgbModule.forRoot()
  ],
  entryComponents: [
    UserEditSiteComponent,
    ConfirmationDialogComponent,
    UserCreateSupplierComponent
  ],
  providers: [
    AuthGuardService, 
    AuthService, 
    QuestionControlService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    }, {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true
    }, 
    { provide: LOCALE_ID, useValue: 'ro' },
    MessagesService,
    UserPdfService,
    AmplifyService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
