import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { PaymentSummaryComponent } from './components/payment-summary/payment-summary.component';
import { UserPaymentsComponent } from './components/user-payments/user-payments.component';
import { UserPaymentComponent } from './components/user-payment/user-payment.component';
import { UserPaymentDetailsComponent } from './components/user-payment-details/user-payment-details.component';
import { UserPendingPaymentsComponent } from './components/user-pending-payments/user-pending-payments.component';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    RouterModule
  ],
  declarations: [
    PaymentSummaryComponent,
    UserPaymentsComponent,
    UserPaymentComponent,
    UserPaymentDetailsComponent,
    UserPendingPaymentsComponent
  ],
  entryComponents: [
    PaymentSummaryComponent
  ],
  exports: [
    UserPendingPaymentsComponent
  ]
})
export class UserPaymentsModule { }
