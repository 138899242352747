import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormControl } from '@angular/forms';

import { ConfigService } from '../../../services/config.service';

import { Basket } from '../../model/basket';

import { UserPaymentService } from '../../services/user-payment.service';

@Component({
  selector: 'app-user-payment',
  templateUrl: './user-payment.component.html',
  styleUrls: ['./user-payment.component.css']
})
export class UserPaymentComponent implements OnInit {
  @Input() payment: any = {};
  
  form: FormGroup;
  
  constructor(private paymentService: UserPaymentService, private config: ConfigService, private modalService: NgbModal) { }

  ngOnInit() {
    this.form = new FormGroup({
      env_key: new FormControl(''),
      data: new FormControl('')
    });
  }

  startPayment() {
    this.paymentService.getBasket(this.payment.id, true).subscribe(payment => {
      const fullPayment = payment.data.basket;
      //
      (document.getElementById(`payment-form-key-${this.payment.id}`) as HTMLInputElement).value = fullPayment.mobilPayData.envKey;
      (document.getElementById(`payment-form-data-${this.payment.id}`) as HTMLInputElement).value = fullPayment.mobilPayData.envData;
      (document.getElementById(`payment-form-${this.payment.id}`) as HTMLFormElement).submit();
    });
  }
}
