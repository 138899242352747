import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of, Subject } from 'rxjs';
//
import { Auth } from 'aws-amplify';
import { Storage } from 'aws-amplify';

const uuid = require('uuid');

import { Invoice, InvoicesResponse } from '../model/invoice';

@Injectable({
  providedIn: 'root'
})
export class UserPdfService {
//  private pdfUrl = 'https://cwn0b46yol.execute-api.eu-west-1.amazonaws.com/prod/pdf';
  private pdfUrl = 'https://bqbw8i3fq6.execute-api.eu-west-1.amazonaws.com/prod/pdf-convert';
  
  constructor(private http: HttpClient) { }

  async uploadPdf(file, progressCallback): Promise<String> {
    const name = `${uuid()}.pdf`;
    const credentials = await Auth.currentUserCredentials();
    // upload file
    if (file.size > 1 * 1024 * 1024) throw new Error('Dimensiunea fisierului este prea importanta: maximum 1MB');
    const result = await Storage.put(name, file, {
        level: 'private',
        contentType: 'application/pdf',
        progressCallback
    });
    //
    return `${credentials.identityId}/${name}`;
  }
  convertPdf(documentName): Observable<Invoice> {
/*    const fields = ['supplier', 'customerCode', 'description', 'payCode', 'invoiceId', 'amount', 'date', 'dueDate', 'balance', 'origin'];
    const select = `mutation PDF_Decode_Mutation{
      decodePDF(
        document:"${documentName}"
      ) {${fields.join(',')}}
    }`;
    return this.http.post<InvoicesResponse>(`${this.pdfUrl}`, {
      query: select
    });*/
    return this.http.post<Invoice>(`${this.pdfUrl}`, {
      document: `${documentName}`
    });    
  }
}
