import { Injectable } from '@angular/core';
import { Observable, of, Subject } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
//
import { Invoice, InvoicesResponse } from '../model/invoice';

@Injectable({
  providedIn: 'root'
})
export class InvoicesService {
  private invoicesUrl: string = 'https://rnnn74tuqj.execute-api.eu-west-1.amazonaws.com/prod/invoices';
  private pendingInvoicesSubject: Subject<InvoicesResponse> = new Subject();
  private invoicesSubject: Subject<InvoicesResponse> = new Subject();
  private selectedInvoicesSubject: Subject<InvoicesResponse> = new Subject();

  constructor(private http: HttpClient) { }

  getInvoices(onlyPending=false): Subject<InvoicesResponse> {
    const fields = ['id', 'supplier', 'account', 'customerCode', 'description', 'payCode', 'invoiceId', 'amount', 'date', 'dueDate', 'site', 
      'balance', 'origin', 'hasDocument', 'status', 'statusMessage'].join(',');
    const select = `{invoices(pending:${onlyPending}) {${fields}}}`;
    const subject = (onlyPending ? this.pendingInvoicesSubject : this.invoicesSubject);
    this.http.get<InvoicesResponse>(`${this.invoicesUrl}?query=${encodeURIComponent(select)}`).subscribe(data => {
      for (let invoice of data.data.invoices) {
        if (invoice.balance == null) invoice.balance = invoice.amount;
        invoice.dueDate = invoice.dueDate || invoice.date;
      }
      subject.next(data);
    });
    return subject;
  }
  getInvoicesSubject(onlyPending=false): Subject<InvoicesResponse> {
    return onlyPending ? this.pendingInvoicesSubject : this.invoicesSubject;
  }
  getSelectedInvoices(): Subject<InvoicesResponse> {
    const fields = ['id', 'supplier', 'account', 'invoiceId', 'selectedAmount'].join(',');
    const select = `{invoicesSelection{${fields}}}`;
    this.http.get<InvoicesResponse>(`${this.invoicesUrl}?query=${encodeURIComponent(select)}`).subscribe(data => {
      this.selectedInvoicesSubject.next(data);
    });
    return this.selectedInvoicesSubject;
  }
  getSelectedInvoicesSubject(): Subject<InvoicesResponse> {
    return this.selectedInvoicesSubject;
  }
  getDownloadUrl(invoice): Observable<InvoicesResponse> {
    const select = `{document_url(invoiceId:"${invoice.invoiceId}",supplier:"${invoice.supplier}")}`;
    return this.http.get<InvoicesResponse>(`${this.invoicesUrl}?query=${encodeURIComponent(select)}`);
  }
  createInvoice(invoice): Observable<InvoicesResponse> {
    const valString = ['supplier', 'description', 'customerCode', 'payCode', 'site', 'invoiceId'].reduce((prev: any, ff: any) => {
      if (invoice[ff]) prev.push(`${ff}:"${invoice[ff]}"`);
      return prev;
    }, []).join(',');
    const fields = ['id', 'supplier', 'account', 'customerCode', 'description', 'payCode', 'site', 'invoiceId', 'amount', 'date', 'balance', 'origin'];
    const select = `mutation Invoice_mutation{
        createInvoice(invoice:{
        account:"account",
        ${valString},
        amount:${invoice.amount},
        balance:${invoice.balance || invoice.amount},
        date:"${new Date(invoice.date).toISOString().split('T')[0]}",
        dueDate:"${new Date(invoice.dueDate).toISOString().split('T')[0]}",
        origin: "user"
      },document:"${invoice.tempDocument}") {${fields.join(',')}}
    }`;
    return this.http.get<InvoicesResponse>(`${this.invoicesUrl}?query=${encodeURIComponent(select)}`);
  }
  selectInvoice(invoice, amount): Observable<InvoicesResponse> {
    invoice.selectedAmount = amount;
    const select = `mutation Invoice_mutation{
      selectInvoice(
        id: "${invoice.id}",
        selectedAmount: ${amount}
      ) {id,selectedAmount}
    }`;
    this.http.get<InvoicesResponse>(`${this.invoicesUrl}?query=${encodeURIComponent(select)}`).subscribe(data => {
      this.selectedInvoicesSubject.next({ 
        data: {
          invoicesSelection: data.data.selectInvoice
        }
      });
    });
    return this.selectedInvoicesSubject;
  }
  deleteInvoice(invoice): Observable<InvoicesResponse> {
    const select = `mutation Invoice_mutation{
      deleteInvoice(
        supplier: "${invoice.supplier}",
        invoiceId: "${invoice.invoiceId}"
      ) {invoiceId}
    }`;
    return this.http.get<InvoicesResponse>(`${this.invoicesUrl}?query=${encodeURIComponent(select)}`);
  }
  archiveInvoice(invoice): Observable<InvoicesResponse> {
    const select = `mutation Invoice_mutation{
      archiveInvoice(
        supplier: "${invoice.supplier}",
        invoiceId: "${invoice.invoiceId}"
      ) {invoiceId}
    }`;
    return this.http.get<InvoicesResponse>(`${this.invoicesUrl}?query=${encodeURIComponent(select)}`);
  }
}
