import { Component, Input, OnInit }  from '@angular/core';
import { FormGroup }                 from '@angular/forms';
import { Observable, of } from 'rxjs';
import { Router } from '@angular/router';

import { QuestionBase }              from '../model/question-base';
import { QuestionControlService }    from '../services/question-control.service';
import { MessagesService } from '../services/messages.service'

@Component({
  selector: 'app-dynamic-form',
  templateUrl: './dynamic-form.component.html',
  providers: [ QuestionControlService ]
})
export class DynamicFormComponent implements OnInit {

  @Input() questions: QuestionBase<any>[] = [];
  @Input() onSubmitService: (form:FormGroup) => Observable<any> = (values) => { return null };
  @Input() returnPath: string;
  form: FormGroup;
  payLoad = '';
  questionsMap = {};

  constructor(private qcs: QuestionControlService, private msg: MessagesService, private router: Router) {  }

  ngOnInit() {
    this.questionsMap = this.questions.reduce((prev, crt) => {
      prev[crt.key] = crt;
      return prev;
    }, {});
    this.form = this.qcs.toFormGroup(this.questions);
  }

  @Input()
  set formData(data) {
    if (!this.form) return;
    // cleanup
    data = Object.keys(data).reduce((prev: any, crt: string) => {
      if (data[crt] != null) prev[crt] = data[crt];
      return prev;
    }, {});
    if (data) this.form.patchValue(data);
    else this.form.reset();
  }
  getInvalidControls(form) {
    return Object.keys(form.controls).filter(controlName => form.controls[controlName].invalid).map(controlName => this.questionsMap[controlName].label).join(',');
  }
  onSubmit(form) {
    if (!form.valid) {
      this.msg.addErrors([{ message: `Valorile "${this.getInvalidControls(form)}" sint invalide, corectati pentru a putea continua`}]);
      return;
    }
    this.onSubmitService(form.value).subscribe(data => {
      if (!data) {
        this.msg.addMessage('Eroare interna (1)', 'error');
        return;
      }
      //
      if (!data.errors) {
        this.form.patchValue(data.data);
        this.msg.addSuccess();
        if (this.returnPath) {
          setTimeout(() => {
            this.router.navigate([this.returnPath]);
          }, 3*1000);
        }
        return;
      }
      //
      for (let err of data.errors) {
        this.msg.addMessage(err.message, 'error');
      }
    });
  }
  onReturn() {
    this.router.navigate([this.returnPath]);
  }
}