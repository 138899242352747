import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
//
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
//
import { Invoice, SelectedInvoice, InvoicesResponse } from '../model/invoice';
import { InvoicesService } from '../services/invoices.service';
import { UserSupplierService } from '../services/user-supplier.service';
import { MessagesService } from '../services/messages.service';

import { PaymentSummaryComponent } from '../user-payments/components/payment-summary/payment-summary.component';

@Component({
  selector: 'app-user-pending-invoices',
  templateUrl: './user-pending-invoices.component.html',
  styleUrls: ['./user-pending-invoices.component.css']
})
export class UserPendingInvoicesComponent implements OnInit {
  //
  invoices: Invoice[];
  selectedInvoicesMap: any = {};
  suppliersMap: any = {};
  sitesMap: any = {};
  totalSelected: number = 0;
  hasInvoices = true;
  //
  constructor(private invoicesService: InvoicesService, private supplierService: UserSupplierService, private router: Router, 
      private msg: MessagesService, private modalService: NgbModal) { }
    //
    getCompositeId(invoice: Invoice) {
      return `${invoice.id}`;
  }
  mergeInformation() {
    if (this.invoices)
      for (let invoice of this.invoices) {
        invoice.supplierName = (this.suppliersMap[invoice.supplier] && this.suppliersMap[invoice.supplier].name) || invoice.supplier;
        invoice.siteDescription = invoice.site && ((this.sitesMap[`${invoice.supplier}.${invoice.site}`] && this.sitesMap[`${invoice.supplier}.${invoice.site}`].description) || invoice.site);
        //
        invoice.selectedAmount = (this.selectedInvoicesMap[this.getCompositeId(invoice)] ? this.selectedInvoicesMap[this.getCompositeId(invoice)].selectedAmount : 0);
      }
  }
  //
  getSuppliers() {
    this.supplierService.getSuppliers().subscribe(data => {
      this.suppliersMap = data.data.suppliers.reduce((prev, supplier) => {
        prev[supplier.id] = supplier;
        if (supplier.cui) prev[supplier.cui] = supplier;
        return prev;
      }, {});
      // apply to invoices
      this.mergeInformation();
    });
  }
  getSites() {
    this.supplierService.getSites().subscribe(data => {
      this.sitesMap = data.data.sites.reduce((prev, site) => {
        prev[`${site.supplier}.${site.id}`] = site;
        return prev;
      }, {});
      // apply to invoices
      this.mergeInformation();
    });
  }
  getSelectedInvoices() {
    this.invoicesService.getSelectedInvoices();
  }
  getInvoices() {
    this.invoicesService.getInvoices(true).subscribe(data => {
      this.hasInvoices = !!data.data.invoices.length;
      this.invoices = data.data.invoices.sort((a, b) => {
        return new Date(a.dueDate).getTime() - new Date(b.dueDate).getTime();
      });
      // apply supplier
      this.mergeInformation();
    });
  }
  selectInvoice(invoice) {
    this.invoicesService.selectInvoice(invoice, invoice.selectedAmount);  
  }
  create() {
    this.router.navigate(['create-invoice']);
  }

  startPayment() {
    this.modalService.open(PaymentSummaryComponent, { centered: true });
  }
    
  ngOnInit() {
    //
    this.invoicesService.getSelectedInvoicesSubject().subscribe(data => {
      this.totalSelected = 0;
      this.selectedInvoicesMap = data.data.invoicesSelection.reduce((prev, crt) => {
        prev[`${crt.id}`] = crt;
        this.totalSelected += crt.selectedAmount;
        return prev;
      }, {});
      // apply to invoices
      this.mergeInformation();
    });
    //
    this.getSuppliers();
    this.getSites();
    this.getInvoices();
    this.getSelectedInvoices();
  }

}
