import { Component, OnInit, Input } from '@angular/core';
import { FormControl, FormGroup, AbstractControl } from '@angular/forms';
import { Router } from '@angular/router';

import { TextboxQuestion } from '../model/question-textbox';
import { QuestionBase } from 'src/app/model/question-base';
import { DynamicFormComponent } from '../dynamic-form/dynamic-form.component';
import { QuestionControlService }    from '../services/question-control.service';

import { UserSupplierService } from '../services/user-supplier.service';

import * as IBAN from 'iban';

@Component({
  selector: 'app-user-create-supplier',
  templateUrl: './user-create-supplier.component.html',
  styleUrls: ['./user-create-supplier.component.css']
})
export class UserCreateSupplierComponent implements OnInit {
  @Input() supplier;

  questions: QuestionBase<any>[] = [
    new TextboxQuestion({
      key: 'id',
      label: 'Id',
      required: false,
      order: 99,
      hidden: true
    }),
    new TextboxQuestion({
      key: 'name',
      label: 'Furnizor',
      required: true,
      order: 1
    }),
    new TextboxQuestion({
      key: 'description',
      label: 'Denumire',
      required: false,
      order: 2
    }),
    new TextboxQuestion({
      key: 'cui',
      label: 'CUI',
      required: false,
      order: 3
    }),
    new TextboxQuestion({
      key: 'iban',
      label: 'IBAN',
      required: true,
      order: 4,
      validators: [this.ibanValidator()]
    })
  ].sort((a, b) => a.order - b.order);
  constructor(private supplierService: UserSupplierService) { }

  ngOnInit() {
  }

  ibanValidator() {
    return (control: AbstractControl): {[key: string]: any} | null => {
      return IBAN.isValid(control.value) ? null : {'iban': { value: control.value }};
    };
  }
  getSubmitHandler() {
    const service = this.supplier && this.supplier.id ? this.supplierService.modifySupplier : this.supplierService.createSupplier;
    return service.bind(this.supplierService);
  }
}
