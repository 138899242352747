import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup }                 from '@angular/forms';
import { Observable, of } from 'rxjs';

import { QuestionBase }              from '../model/question-base';
import { QuestionControlService }    from '../services/question-control.service';
import { MessagesService } from '../services/messages.service'

@Component({
  selector: 'app-dynamic-form-modal',
  templateUrl: './dynamic-form-modal.component.html',
  styleUrls: ['./dynamic-form-modal.component.css']
})
export class DynamicFormModalComponent implements OnInit {
  @Input() title = '';
  @Input() questions: QuestionBase<any>[] = [];
  @Input() onSubmitService: (form:FormGroup) => Observable<any> = (values) => { return null };
  @Input() formData = {};
  form: FormGroup;
  
  constructor(public activeModal: NgbActiveModal, private qcs: QuestionControlService, private msg: MessagesService) { }

  ngOnInit() {
    this.form = this.qcs.toFormGroup(this.questions);
    // cleanup
    const data = Object.keys(this.formData).reduce((prev: any, crt: string) => {
      if (this.formData[crt] != null) prev[crt] = this.formData[crt];
      return prev;
    }, {});
    if (data) this.form.patchValue(data);
    else this.form.reset();
  }

  onSubmit(form) {
    this.onSubmitService(form.value).subscribe(data => {
      if (!data) {
        this.msg.addMessage('Eroare interna (1)', 'error');
        return;
      }
      //
      if (!data.errors) {
        this.form.patchValue(data.data);
        this.msg.addSuccess();
        this.activeModal.close();
        return;
      }
      //
      for (let err of data.errors) {
        this.msg.addMessage(err.message, 'error');
      }
    });
  }
}
