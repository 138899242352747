import { NgModule }             from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CommonModule } from '@angular/common';
//
import { AuthGuardService } from './auth/auth-guard.service';
import { AuthCallbackComponent } from './auth-callback/auth-callback.component';
import { AuthSignoutComponent } from './auth-signout/auth-signout.component';
import { UnauthorizedComponent } from './unauthorized/unauthorized.component';
//
import { UserInvoicesComponent } from './user-invoices/user-invoices.component';
import { UserDashboardComponent } from './user-dashboard/user-dashboard.component';
import { UserCreateInvoiceComponent } from './user-create-invoice/user-create-invoice.component';
import { UserSuppliersComponent } from './user-suppliers/user-suppliers.component';
import { UserCreateSupplierComponent } from './user-create-supplier/user-create-supplier.component';

const routes: Routes = [{
  path: 'dashboard', component: UserDashboardComponent, canActivate: [AuthGuardService]
}, {
  path: 'create-invoice', component: UserCreateInvoiceComponent, canActivate: [AuthGuardService]
}, {
  path: 'suppliers', component: UserSuppliersComponent, canActivate: [AuthGuardService]
}, {
  path: 'invoices', component: UserInvoicesComponent, canActivate: [AuthGuardService]
}, {
  path: 'create-supplier', component: UserCreateSupplierComponent, canActivate: [AuthGuardService]
}, {
  path: 'auth-callback', component: AuthCallbackComponent
}, {
  path: 'auth-signout', component: AuthSignoutComponent
}, {
  path: 'unauthorized', component: UnauthorizedComponent
}, {
//  path: '', redirectTo: '/dashboard', pathMatch: 'full'
  path: '', component: UnauthorizedComponent
}];

@NgModule({
  imports: [ RouterModule.forRoot(routes) ],
  exports: [ RouterModule ]
})

export class AppRoutingModule { }
