import { Injectable } from '@angular/core';
import { Observable, of, Subject } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
//
import { Basket, CreateBasketResponse, GetBasketResponse, GetBasketsResponse } from '../model/basket';

import { ConfigService } from '../../services/config.service';

@Injectable({
  providedIn: 'root'
})
export class UserPaymentService {

  constructor(private config: ConfigService, private http: HttpClient) { }

  createBasket(): Observable<CreateBasketResponse> {
    let fields = ['id', 'account', 'invoices { supplier, invoiceId, selectedAmount }', 'amount', 'date', 'mobilPayData { envKey, envData }'];
    const select = `mutation Basket_mutation{
      createBasket(
        name: "basket"
      ) {${fields.join(',')}}
    }`;
    return this.http.post<CreateBasketResponse>(`${this.config.endpoints.basket}`, {
      query: select
    });
  }

  getBaskets(onlyPending=false): Observable<GetBasketsResponse> {
    let fields = ['id', 'account', 'invoices { supplier, invoiceId, selectedAmount }', 'amount', 'date', 'status', 'statusMessage', 'statusDescription'];
    const select = `{baskets(pending:${onlyPending}) {${fields.join(',')}}}`;
    return this.http.post<GetBasketsResponse>(`${this.config.endpoints.basket}`, {
      query: select
    });
  }

  getBasket(id, withPayData=false): Observable<GetBasketResponse> {
    let fields = ['id', 'account', 'invoices { supplier, invoiceId, selectedAmount }', 'amount', 'date', 'status', 'statusMessage', 'statusDescription',
        'history { date, status, statusMessage }'];
    if (withPayData) fields.push('mobilPayData { envKey, envData }');
    const select = `{basket(
        id: "${id}"
      ) {${fields.join(',')}}
    }`;
    return this.http.post<GetBasketResponse>(`${this.config.endpoints.basket}`, {
      query: select
    });
  }

  confirmBasket(id): Observable<GetBasketResponse> {
    const select = `mutation Basket_mutation{
      confirmBasket(
        id: "${id}"
      ) {id, status, statusMessage}
    }`;
    return this.http.post<GetBasketResponse>(`${this.config.endpoints.basket}`, {
      query: select
    });
  }
}
