import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup }                 from '@angular/forms';

import { ConfigService } from '../../../services/config.service';
import { MessagesService } from '../../../services/messages.service';
import { UserSupplierService } from '../../../services/user-supplier.service';

import { UserPaymentService } from '../../services/user-payment.service';

@Component({
  selector: 'app-payment-summary',
  templateUrl: './payment-summary.component.html',
  styleUrls: ['./payment-summary.component.css']
})
export class PaymentSummaryComponent implements OnInit {
  @Input() basket;
  @ViewChild('paymentSummaryForm') paymentSummaryForm;

  paymentsFormGroup = new FormGroup({});
  suppliersMap = {};

  constructor(private config: ConfigService, private supplierService: UserSupplierService, public activeModal: NgbActiveModal, private paymentService: UserPaymentService, private msg: MessagesService) { }

  mergeInformation() {
    if (this.basket.invoices)
      for (let invoice of this.basket.invoices) {
        invoice.supplierName = (this.suppliersMap[invoice.supplier] && this.suppliersMap[invoice.supplier].name) || invoice.supplier;
      }
  }
  //
  getSuppliers() {
    this.supplierService.getSuppliers().subscribe(data => {
      this.suppliersMap = data.data.suppliers.reduce((prev, supplier) => {
        prev[supplier.id] = supplier;
        if (supplier.cui) prev[supplier.cui] = supplier;
        return prev;
      }, {});
      // apply to invoices
      this.mergeInformation();
    });
  }

  confirmBasket() {
    this.paymentService.confirmBasket(this.basket.id).subscribe(data => {
      if (data.errors) return this.msg.addErrors(data.errors);
      //
      this.paymentSummaryForm.nativeElement.submit();
    })
  }
  ngOnInit() {
    this.getSuppliers();
    this.paymentService.createBasket().subscribe(basketResponse => {
      this.basket = basketResponse.data.createBasket;
      //
      this.mergeInformation();
    });
  }

}
